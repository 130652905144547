@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
/* === Plugin styles === */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

:root {
  --blue: #5e50f9;
  --indigo: #6610f2;
  --purple: #6a008a;
  --pink: #e91e63;
  --red: #f96868;
  --orange: #f2a654;
  --yellow: #f6e84e;
  --green: #46c35f;
  --teal: #58d8a3;
  --cyan: #57c7d4;
  --white: #ffffff;
  --gray: #6c757d;
  --gray-dark: #0f1531;
  --blue: #5e50f9;
  --indigo: #6610f2;
  --purple: #6a008a;
  --pink: #e91e63;
  --red: #f96868;
  --orange: #f2a654;
  --yellow: #f6e84e;
  --green: #46c35f;
  --teal: #58d8a3;
  --cyan: #57c7d4;
  --white: #ffffff;
  --gray: #434a54;
  --gray-light: #aab2bd;
  --gray-lighter: #e8eff4;
  --gray-lightest: #e6e9ed;
  --black: #000000;
  --primary: #be9528;
  --primary-hover: #b59028;
  --secondary: #ddd;
  --secondary-hover: #d5d5d5;
  --success: #386f23;
  --success-hover: #386f23;
  --info: #198ae3;
  --warning: #fed713;
  --danger: #c12746;
  --danger-hover: #c12746;
  --light: #f8f9fa;
  --dark: #24242d;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-primary: "Poppins", sans-serif;
}

* {
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: var(--primary) transparent;
}

*::-webkit-scrollbar {
  height: 3px !important;
  width: 5px !important;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border: transparent;
  border-radius: 25px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

nav {
  display: block;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: var(--font-primary);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #343a40;
  text-align: left;
  background-color: #fff;
}

h1,
h2,
h3,
h4 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ul ul {
  margin-bottom: 0;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

code {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  font-size: 1em;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button {
  text-transform: none;
}

button,
[type="button"],
[type="reset"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

.container-fluid {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-left: -20px;
}

.col-lg-12,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-4,
.col-12 {
  position: relative;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
@media (min-width: 992px) {
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.form-control {
  display: block;
  width: 100%;
  height: 2.875rem;
  padding: 0.875rem 1.375rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::-moz-placeholder {
  color: #c9c8c8;
  opacity: 1;
}
.form-control::placeholder {
  color: #c9c8c8;
  opacity: 1;
}
.form-control:disabled {
  background-color: #e9ecef;
  opacity: 1;
}

input[type="time"].form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #343a40;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.875rem 2.5rem;
  font-size: 0.875rem;
  line-height: 1;
  border-radius: 0.1875rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #343a40;
  text-decoration: none;
}
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: red;
  border-color: #13a9d4;
}
.btn-primary:hover {
  color: #fff;
  background-color: #5840a3;
  border-color: #533d9a;
}
.btn-primary:focus {
  color: #fff;
  background-color: #5840a3;
  border-color: #533d9a;
  box-shadow: 0 0 0 0.2rem rgba(128, 106, 196, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #13a9d4;
  border-color: #13a9d4;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #533d9a;
  border-color: #4e3991;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(128, 106, 196, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #24242d;
  border-color: #24242d;
}
.btn-dark:hover {
  color: #fff;
  background-color: #131318;
  border-color: #0d0d11;
}
.btn-dark:focus {
  color: #fff;
  background-color: #131318;
  border-color: #0d0d11;
  box-shadow: 0 0 0 0.2rem rgba(69, 69, 77, 0.5);
}
.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #24242d;
  border-color: #24242d;
}
.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #0d0d11;
  border-color: #08080a;
}
.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(69, 69, 77, 0.5);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control {
  margin-left: -1px;
}
.input-group > .form-control:focus {
  z-index: 3;
}
.input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend {
  display: flex;
}
.input-group-prepend .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.875rem 1.375rem;
  margin-bottom: 0;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 2px;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
  box-shadow: none !important;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.bg-primary {
  background-color: #13a9d4 !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #533d9a !important;
}

.bg-info {
  background-color: #198ae3 !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #146eb5 !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #ebedf2 !important;
}

.border-0 {
  border: 0 !important;
}

.d-none {
  display: none !important;
}

.d-flex,
.page-header {
  display: flex !important;
}
@media (min-width: 768px) {
  .d-md-block {
    display: block !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
}

.flex-row {
  flex-direction: row !important;
}

.justify-content-center {
  justify-content: center !important;
}

.page-header {
  justify-content: space-between !important;
}

.align-items-center,
.page-header {
  align-items: center !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-self-center {
  align-self: center !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.mx-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.rtl .navbar .navbar-menu-wrapper .search-field,
.mx-0 {
  margin-left: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
}

.mx-2 {
  margin-left: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.rtl .navbar .navbar-menu-wrapper .search-field {
  margin-right: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.px-0 {
  padding-right: 0 !important;
}

.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.px-4 {
  padding-left: 1.5rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.py-5 {
  padding-bottom: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .px-sm-5 {
    padding-right: 3rem !important;
  }

  .px-sm-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 992px) {
  .pr-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pl-lg-4 {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
}

.font-weight-light {
  font-weight: 300 !important;
}

.text-white {
  color: #ffffff !important;
}

.color-primary {
  color: #0f408d !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #493688 !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }

  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
}
/* Animation Mixins */
@keyframes dropdownAnimation {
  from {
    opacity: 0;
    transition: all 0.3s;
  }
  to {
    opacity: 1;
    transition: all 0.3s;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
/* Miscellaneous Mixins */

/* === Core Styles === */

/* Typography */
body {
  font-size: 1rem;
  font-family: var(--font-primary) !important;
  font-weight: initial;
  line-height: normal;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
  font-family: var(--font-primary);
}

p {
  font-size: 0.875rem;
}

h1,
.h1 {
  font-size: 2.19rem;
}

h2,
.h2 {
  font-size: 1.88rem;
}

h3,
.h3 {
  font-size: 1.56rem;
}

h4,
.h4 {
  font-size: 1.13rem;
}

p {
  font-size: 0.9375rem;
  line-height: 1.5;
}

.display-1 {
  font-size: 3.75rem;
}
@media (max-width: 991px) {
  .display-1 {
    font-size: 3rem;
  }
}

address p {
  margin-bottom: 0;
}

.error-page h1 {
  font-size: 12rem;
}
@media (max-width: 991px) {
  .error-page h1 {
    font-size: 8rem;
  }
}

/* Reset Styles */
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

.form-control,
.form-control:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.form-control {
  box-shadow: none;
}
select.form-control {
  position: relative;
}
select.form-control::after {
  content: "";
  position: absolute;
  background-image: url(../assets/svg/arrowDown.svg);
}
select.form-control:focus,
.form-control:focus {
  outline: 0;
  box-shadow: none;
}

a,
div,
h1,
h2,
h3,
h4,
p,
span {
  text-shadow: none;
}

[type="button"]:focus,
a:active,
a:focus,
a:visited,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: 0;
}

input,
.form-control:focus,
input:focus,
button:focus {
  outline: none;
  outline-width: 0;
  outline-color: transparent;
  box-shadow: none;
  outline-style: none;
}

.btn,
.btn:active,
.btn:focus,
.btn:hover,
.btn:visited,
a,
a:active,
a:checked,
a:focus,
a:hover,
a:visited,
body,
button,
button:active,
button:hover,
button:visited,
div,
input,
input:active,
input:focus,
input:hover,
input:visited {
  box-shadow: none;
}

.btn.active:focus,
.btn:active:focus,
.btn:focus,
button,
button:active,
button:checked,
button:focus,
button:hover,
button:visited {
  outline: 0;
  outline-offset: 0;
}

a:focus,
input:focus {
  border-color: transparent;
  outline: none;
}

@media (max-width: 991px) {
  .navbar .navbar-brand-wrapper .brand-logo {
    display: none;
  }
  .navbar .navbar-brand-wrapper .brand-logo-mini {
    display: inline-block;
  }
}
/* Miscellanoeous */
body {
  overflow-x: hidden;
  padding-right: 0;
}

body {
  background: #f6f6f6;
}

*:-moz-full-screen,
*:-webkit-full-screen,
*:fullscreen *:-ms-fullscreen {
  overflow: auto;
}

.page-body-wrapper {
  min-height: calc(100vh - 70px);
  padding-top: 70px;
  display: flex;
  flex-direction: row;
  padding-left: 0;
  padding-right: 0;
}
.page-body-wrapper.full-page-wrapper {
  min-height: 100vh;
  padding-top: 0;
}
.page-body-wrapper.full-page-wrapper .main-panel {
  width: 100%;
  transition: none;
}
.page-body-wrapper.full-page-wrapper .main-panel .content-wrapper {
  padding: 0;
  background-color: #121212;
}
.page-body-wrapper.full-page-wrapper .main-panel .content-wrapper > div {
  height: 100%;
}

.main-panel {
  transition: width 0.25s ease, margin 0.25s ease;
  width: calc(100% - 260px);
  min-height: calc(100vh - 70px);
  margin-left: auto;
  display: flex;
  flex-direction: column;
}
@media (max-width: 991px) {
  .main-panel {
    margin-left: 0;
    width: 100%;
  }
}

.content-wrapper {
  background: #fafafa;
  padding: 0.75rem 2.25rem 2.75rem;
  width: 100%;
  flex-grow: 1;
}

.container-scroller {
  overflow: hidden;
}

code {
  padding: 5px;
  color: #fe7c96;
  font-weight: 300;
  font-size: 0.875rem;
  border-radius: 4px;
}

.page-header {
  margin: 0 0 0.75rem 0;
  height: 46px;
}

.page-title {
  color: #000;
  font-size: 1.125rem;
  margin-bottom: 0;
}

/* Utilities */

.flex-grow {
  flex-grow: 1;
}

.font-weight-light {
  font-weight: 300;
}

.cursor-pointer {
  cursor: pointer;
}

/* Demo Styles */

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
/* Dashboard */

/* === Components === */

/* Forms */

.input-group-prepend {
  color: #c9c8c8;
  width: auto;
  border: none;
}

.input-group-prepend .input-group-text {
  border-color: #ebedf2;
  padding: 0.575rem 0.75rem;
  color: #c9c8c8;
}

.input-group-prepend button {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.form-control {
  border: 1px solid #ebedf2;
  font-size: 0.8125rem;
}

/* Checkboxes and Radios */

/* Icons */

/* Tables */

/* Accordions */

/* Buttons */
.btn {
  font-size: 0.875rem;
  line-height: 1;
  font-weight: 700;
  margin-right: 3px;
  /* Buttons with only icons */
  /* Buttons with icon and text */
}
.btn i {
  font-size: 1rem;
}
.btn.btn-fw {
  min-width: 150px;
}
/*social buttons*/

/* inverse buttons */

.btn-primary:not(.btn-light) {
  color: #ffffff;
}
.btn-primary:not(.btn-light):hover,
.btn-primary:not(.btn-light):focus,
.btn-primary:not(.btn-light):active {
  color: #ffffff;
}
.btn-primary:not(.btn-light):focus,
.btn-primary:not(.btn-light):active {
  background: #13a9d4;
  border-color: #13a9d4;
}

.btn-light:not(.btn-light) {
  color: #ffffff;
}
.btn-light:not(.btn-light):hover,
.btn-light:not(.btn-light):focus,
.btn-light:not(.btn-light):active {
  color: #ffffff;
}
.btn-light:not(.btn-light):focus,
.btn-light:not(.btn-light):active {
  background: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-dark:not(.btn-light) {
  color: #ffffff;
}
.btn-dark:not(.btn-light):hover,
.btn-dark:not(.btn-light):focus,
.btn-dark:not(.btn-light):active {
  color: #ffffff;
}
.btn-dark:not(.btn-light):focus,
.btn-dark:not(.btn-light):active {
  background: #24242d;
  border-color: #24242d;
}

/* gradient buttons */
.btn-gradient-primary {
  background: linear-gradient(to right, #da8cff, #9a55ff);
  border: 0;
  transition: opacity 0.3s ease;
}
.btn-gradient-primary:not([disabled]):not(.disabled):active,
.btn-gradient-primary:not([disabled]):not(.disabled).active {
  background: linear-gradient(to right, #da8cff, #9a55ff);
}
.btn-gradient-primary:hover {
  opacity: 0.8;
}
.btn-gradient-primary:not(.btn-gradient-light) {
  color: #ffffff;
}
.btn-gradient-primary:not(.btn-gradient-light):hover,
.btn-gradient-primary:not(.btn-gradient-light):focus,
.btn-gradient-primary:not(.btn-gradient-light):active {
  color: #ffffff;
}

/* Breadcrumbs */

/* inverse breadcrumb */

/* Badges */

/*Badge variations*/

/*Badge gradient variations*/

/*Badge outlined variations*/

/* Cards */

/* Preview */

/* Tooltips */

/* Popovers */

/* Lists */
ul {
  padding-left: 1rem;
  font-size: 0.875rem;
}
ul li {
  line-height: 1.8;
}

/* Timeline */

/* Portfolio */

/* Pricing table */

/* Mail List Container */

/* Mail Sidebar */

/* Message Content */

/* Todo list */
.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 70px - 5.5rem);
  position: fixed;
  width: calc(100% - 260px - 4rem);
}
@media (max-width: 991px) {
  .spinner-wrapper {
    width: 100%;
  }
}
.spinner-wrapper .donut {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: 0.3rem solid rgba(151, 159, 208, 0.3);
  border-top-color: #979fd0;
  animation: 1.5s spin infinite linear;
}

:host-context(.sidebar-icon-only) .spinner-wrapper {
  width: calc(100% - 70px - 4rem);
}

:host-context(.sidebar-mini) .spinner-wrapper {
  width: calc(100% - 185px - 4rem);
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Auth */
.auth {
  height: 100%;
}
.auth .auth-form-light {
  background: #ffffff;
}
.auth .auth-form-dark {
  background: #1a1a1a;
}
.auth .auth-form-dark * {
  color: #fff;
}
.auth .auth-form-dark .form-control {
  background: #ffffff00;
  color: #fff;
}
.auth .auth-form-light .input-group .form-control:focus,
.auth .auth-form-light .input-group .form-control:active {
  border-color: #ebedf2;
}
.auth .brand-logo {
  margin-bottom: 2rem;
}
.auth .brand-logo img {
  width: 70px;
  filter: contrast(0);
}

/* === Plugin Overrides === */

/* No-ui-slider */

/* Slider Color variations */

/* Slick-carousel */
/* React Bootstrap Tables */

/* SweetAlert */

/* Full Calendar */

svg {
  touch-action: none;
}

.map {
  width: 100%;
  height: 500px;
}
@media (max-width: 767px) {
  .map {
    height: 100px;
  }
}

/* === Layout === */
.navbar {
  font-weight: 300;
  background: #fff;
  transition: background 0.25s ease;
  -webkit-transition: background 0.25s ease;
  -moz-transition: background 0.25s ease;
  -ms-transition: background 0.25s ease;
}
.navbar .navbar-brand {
  width: 110px;
}
.navbar .navbar-brand-wrapper {
  transition: width 0.25s ease, background 0.25s ease;
  -webkit-transition: width 0.25s ease, background 0.25s ease;
  -moz-transition: width 0.25s ease, background 0.25s ease;
  -ms-transition: width 0.25s ease, background 0.25s ease;
  background: #fff;
  width: 260px;
  height: 70px;
}
:host-context(.sidebar-dark) .navbar .navbar-brand-wrapper {
  background: #18151e;
}
@media (max-width: 991px) {
  .navbar .navbar-brand-wrapper {
    width: 55px;
  }
}
.navbar .navbar-brand-wrapper .navbar-brand {
  color: #27367f;
  font-size: 1.5rem;
  line-height: 48px;
  margin-right: 0;
  padding: 0.25rem 0 0.25rem 16px;
  width: 100%;
}
.navbar .navbar-brand-wrapper .navbar-brand:active,
.navbar .navbar-brand-wrapper .navbar-brand:focus,
.navbar .navbar-brand-wrapper .navbar-brand:hover {
  color: #1b2658;
}
.navbar .navbar-brand-wrapper .navbar-brand img {
  width: 60px;
  max-width: 100%;
  margin: auto;
  filter: contrast(0);
  vertical-align: middle;
}
.navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini {
  display: none;
}
.navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini img {
  width: 45px;
  /* height: 30px; */
  margin: auto;
}
.navbar .navbar-menu-wrapper {
  transition: width 0.25s ease;
  -webkit-transition: width 0.25s ease;
  -moz-transition: width 0.25s ease;
  -ms-transition: width 0.25s ease;
  color: #9c9fa6;
  padding-left: 24px;
  padding-right: 24px;
  width: calc(100% - 260px);
  height: 70px;
}
@media (max-width: 991px) {
  .navbar .navbar-menu-wrapper {
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.navbar .navbar-menu-wrapper .navbar-toggler {
  border: 0;
  color: inherit;
  height: 70px;
  border-radius: 0px;
  padding-left: 5px;
  padding-right: 20px;
}
.navbar .navbar-menu-wrapper .navbar-toggler:not(.navbar-toggler-right) {
  font-size: 1.5rem;
}
@media (max-width: 991px) {
  .navbar .navbar-menu-wrapper .navbar-toggler:not(.navbar-toggler-right) {
    display: none;
  }
}
@media (max-width: 991px) {
  .navbar .navbar-menu-wrapper .navbar-toggler.navbar-toggler-right {
    padding-left: 15px;
    padding-right: 11px;
    border-right: none;
  }
}
.navbar .navbar-menu-wrapper .search-field .input-group input {
  font-size: 0.875rem;
  padding: 0.5rem;
}
.navbar
  .navbar-menu-wrapper
  .search-field
  .input-group
  input:-ms-input-placeholder {
  font-size: 0.875rem;
  color: #9c9fa6;
  font-weight: 300;
}
.navbar .navbar-menu-wrapper .search-field .input-group input:-moz-placeholder {
  font-size: 0.875rem;
  color: #9c9fa6;
  font-weight: 300;
}
.navbar
  .navbar-menu-wrapper
  .search-field
  .input-group
  input::-moz-placeholder {
  font-size: 0.875rem;
  color: #9c9fa6;
  font-weight: 300;
}
.navbar
  .navbar-menu-wrapper
  .search-field
  .input-group
  input::-webkit-input-placeholder {
  font-size: 0.875rem;
  color: #9c9fa6;
  font-weight: 300;
}
.navbar .navbar-menu-wrapper .search-field .input-group i {
  font-size: 17px;
  margin-right: 0;
  color: #9c9fa6;
}
.navbar .navbar-menu-wrapper .search-field .input-group .input-group-text {
  background: transparent;
}

@media (max-width: 991px) {
  .navbar {
    flex-direction: row;
  }
  .navbar .navbar-brand-wrapper {
    width: 75px;
  }
  .navbar .navbar-brand-wrapper .navbar-brand.brand-logo {
    display: none;
  }
  .navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini {
    display: inline-block;
  }
}
@media (max-width: 480px) {
  .navbar .navbar-brand-wrapper {
    width: 55px;
  }
  .navbar .navbar-brand-wrapper .brand-logo-mini {
    padding-top: 0px;
  }
}
/* Navbar color variations */

.sidebar {
  position: fixed;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  background: #ffffff;
  padding: 0;
  width: 260px;
  z-index: 11;
  transition: width 0.25s ease, background 0.25s ease;
  -webkit-transition: width 0.25s ease, background 0.25s ease;
  -moz-transition: width 0.25s ease, background 0.25s ease;
  -ms-transition: width 0.25s ease, background 0.25s ease;
}
.sidebar .nav {
  overflow: hidden;
  flex-wrap: nowrap;
  flex-direction: column;
}
.sidebar .nav:not(.sub-menu) {
  padding-bottom: 60px;
}
.sidebar .nav .nav-item {
  padding: 0 2.25rem;
  transition-duration: 0.25s;
  transition-property: background;
  -webkit-transition-property: background;
}
.sidebar .nav .nav-item .nav-link {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 1.125rem 0 1.125rem 0;
  color: #777;
  transition-duration: 0.45s;
  transition-property: color;
  -webkit-transition-property: color;
  cursor: pointer;
}
.sidebar .nav .nav-item .nav-link i {
  color: inherit;
}
.sidebar .nav .nav-item .nav-link svg {
  margin-left: auto;
}
.rtl .sidebar .nav .nav-item .nav-link i.menu-icon {
  margin-left: 0;
  margin-right: auto;
}
.sidebar .nav .nav-item .nav-link i.menu-icon:before {
  vertical-align: middle;
}
.sidebar .nav .nav-item .nav-link .menu-title {
  color: inherit;
  display: inline-block;
  font-size: 0.875rem;
  line-height: 1;
  vertical-align: middle;
  font-weight: 500;
}
.sidebar .nav .nav-item .nav-link svg {
  stroke: #999;
}
.sidebar .nav .nav-item.active .nav-link {
  color: #be9528;
}
.sidebar .nav .nav-item.active .nav-link .menu-title {
  color: #be9528;
  font-weight: 700;
}
.sidebar .nav .nav-item.active .nav-link svg {
  stroke: #be9528;
}
.sidebar .nav .nav-item:hover {
  background: #fcfcfc;
}
.sidebar
  .nav:not(.sub-menu)
  > .nav-item:hover:not(.nav-category):not(.nav-profile)
  > .nav-link {
  color: #29323d;
}

/* style for off-canvas menu*/
@media screen and (max-width: 991px) {
  .sidebar-offcanvas {
    position: fixed;
    max-height: calc(100vh - 70px);
    top: 70px;
    bottom: 0;
    overflow: auto;
    right: -260px;
    transition: all 0.25s ease-out;
  }
  .sidebar-offcanvas.active {
    right: 0;
  }
}

/* Layouts */
.navbar.fixed-top + .page-body-wrapper {
  padding-top: 70px;
}

@media (min-width: 992px) {
  .sidebar-icon-only .navbar .navbar-brand-wrapper {
    width: 70px;
  }
  .sidebar-icon-only .navbar .navbar-brand-wrapper .brand-logo {
    display: none;
  }
  .sidebar-icon-only .navbar .navbar-brand-wrapper .brand-logo-mini {
    display: inline-block;
  }
  .sidebar-icon-only .navbar .navbar-menu-wrapper {
    width: calc(100% - 70px);
  }
  .sidebar-icon-only .sidebar {
    width: 70px;
  }
  .sidebar-icon-only .sidebar .nav {
    overflow: visible;
  }
  .sidebar-icon-only .sidebar .nav .nav-item {
    position: relative;
    padding: 0;
  }
  .sidebar-icon-only .sidebar .nav .nav-item .nav-link {
    display: block;
    text-align: center;
  }
  .sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title {
    display: none;
  }
  .sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title {
    border-radius: 0 5px 5px 0px;
  }
  .rtl.sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title {
    border-radius: 5px 0 0 5px;
  }
  .sidebar-icon-only .sidebar .nav .nav-item .nav-link i.menu-icon {
    margin-right: 0;
    margin-left: 0;
  }
  .sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title {
    display: flex;
    align-items: center;
    background: #ffffff;
    padding: 0.5rem 1.4rem;
    left: 70px;
    position: absolute;
    text-align: left;
    top: 0;
    bottom: 0;
    width: 190px;
    z-index: 1;
    line-height: 1.8;
  }
  .rtl.sidebar-icon-only
    .sidebar
    .nav
    .nav-item.hover-open
    .nav-link
    .menu-title {
    left: auto;
    right: 70px;
    text-align: left;
  }
  .sidebar-icon-only
    .sidebar
    .nav
    .nav-item.hover-open
    .nav-link
    .menu-title:after {
    display: none;
  }
  .sidebar-icon-only .main-panel {
    width: calc(100% - 70px);
  }
}

.rtl {
  direction: rtl;
  text-align: right;
}
.rtl .sidebar .nav {
  padding-right: 0;
}

/* Settings Panel */

.btn_primary {
  background-color: var(--primary);
  border: 1px solid var(--primary);
  border-radius: 11px;
  color: var(--black);
  font-family: var(--font-primary);
  font-weight: 600;
  font-size: 16px;
  padding: 14px 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn_primary:hover {
  background-color: var(--primary-hover);
  border: 1px solid var(--primary-hover);
}
.btn_primary_sm {
  background-color: var(--primary);
  border: 1px solid var(--primary);
  border-radius: 11px;
  color: var(--white);
  font-family: var(--font-primary);
  font-weight: 500;
  font-size: 14px;
  padding: 10px 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn_primary_sm:hover {
  background-color: var(--primary-hover);
  border: 1px solid var(--primary-hover);
}
.btn_primary_outline {
  background-color: var(--white);
  border: 1px solid var(--primary);
  border-radius: 11px;
  color: var(--primary);
  font-family: var(--font-primary);
  font-weight: 600;
  font-size: 16px;
  padding: 14px 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn_primary_outline:hover {
  background-color: var(--primary-hover);
  color: var(--black);
  border: 1px solid var(--primary-hover);
}
.btn_primary_outline_sm {
  background-color: var(--white);
  border: 1px solid var(--primary);
  border-radius: 11px;
  color: var(--primary);
  font-family: var(--font-primary);
  font-weight: 500;
  font-size: 14px;
  padding: 10px 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn_primary_outline_sm:hover {
  background-color: var(--primary-hover);
  color: var(--white);
  border: 1px solid var(--primary-hover);
}
.btn_success_outline_sm {
  background-color: var(--white);
  border: 1px solid var(--success);
  border-radius: 11px;
  color: var(--success);
  font-family: var(--font-success);
  font-weight: 500;
  font-size: 14px;
  padding: 10px 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn_success_outline_sm:hover {
  background-color: var(--success-hover);
  color: var(--white);
  border: 1px solid var(--success-hover);
}
.btn_danger_outline_sm {
  background-color: var(--white);
  border: 1px solid var(--danger);
  border-radius: 11px;
  color: var(--danger);
  font-family: var(--font-danger);
  font-weight: 500;
  font-size: 14px;
  padding: 10px 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn_danger_outline_sm:hover {
  background-color: var(--danger-hover);
  color: var(--white);
  border: 1px solid var(--danger-hover);
}
.btn_secondary {
  background-color: var(--secondary);
  border: 1px solid var(--secondary);
  border-radius: 11px;
  color: var(--black);
  font-family: var(--font-primary);
  font-weight: 600;
  font-size: 16px;
  padding: 14px 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn_secondary:hover {
  background-color: var(--secondary-hover);
  border: 1px solid var(--secondary-hover);
}
.btn_secondary_sm {
  background-color: var(--secondary);
  border: 1px solid var(--secondary);
  border-radius: 11px;
  color: var(--black);
  font-family: var(--font-primary);
  font-weight: 500;
  font-size: 14px;
  padding: 10px 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn_secondary_sm:hover {
  background-color: var(--secondary-hover);
  border: 1px solid var(--secondary-hover);
}
.form-label-lg {
  font-size: 18px;
}
.form-label-required::after {
  content: "*";
  color: #ff3951;
}
input.form-control,
select.form-control {
  border-radius: 8px;
  font-family: var(--font-primary);
  padding: 15px 20px;
  height: 54px;
}
input.form-control::placeholder {
  font-size: 14px;
  color: #b5b5b5;
}
input.form-control:focus {
  box-shadow: none;
  border-color: var(--primary);
}
input.form-check-input {
  background-color: var(--primary) !important;
  border-color: #777 !important;
  width: 20px;
  height: 20px;
}
.file_upload_instructions {
  font-size: 12px;
  color: #ff3951;
}
.form-control-icon {
  position: relative;
}
.form-control-icon input.form-control {
  padding: 12px 20px 12px 50px;
}
.form-control-icon svg {
  position: absolute;
  top: 50%;
  left: 18px;
  transform: translateY(-50%);
  font-size: 22px !important;
}
/* login start */
.login_heading {
  font-family: var(--font-primary);
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 0;
}
.login_desc {
  font-family: var(--font-primary);
  font-size: 20px;
}
.forgot_password_link {
  font-size: 14px;
  font-weight: 500;
  color: #ff3951 !important;
  cursor: pointer;
}
.forgot_password_link:hover {
  color: #e7344a !important;
}
/* login end */

/* navbar start */
.navbar-logout-btn {
  color: var(--primary) !important;
  font-size: 20px;
  position: relative;
  padding: initial;
  margin-right: 12px;
  cursor: pointer;
}
.navbar-logout-btn::after {
  content: "Logout";
  font-size: 18px;
  color: #aaa;
  font-weight: 500;
  position: absolute;
  font-style: normal;
  right: 100%;
  height: 100%;
  top: 0;
  display: none;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}
.navbar-logout-btn:hover::after {
  display: flex;
}
/* navbar end */
select.form-control:focus {
  border-color: #be9528;
}
div.card {
  border: none;
}
div.rdt_TableHeadRow {
  font-weight: 600;
  font-size: 13px;
}
.eDpmNf:hover {
  border-bottom-color: rgba(0, 0, 0, 0.12) !important;
  outline-color: rgba(0, 0, 0, 0.02) !important;
}
div.rdt_TableRow:nth-of-type(odd) {
  background-color: #fff;
}
div.rdt_TableRow:nth-of-type(even) {
  background-color: #fff;
}
div.jBfjuD {
  font-size: 14px;
}
div.duaHBH {
  background-color: #be952810;
}
label.badge {
  margin-bottom: 0;
  font-size: 85%;
  font-weight: 500;
}
label.badge-success {
  background-color: #e9fff3;
  color: #2d9d61;
}
label.badge-danger {
  background-color: #fff4f2;
  color: #e03616;
}

.view-more-btn {
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  display: -webkit-box;
  text-decoration: none;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #be9528;
}
.view-more-btn svg {
  position: relative;
  height: 16px;
  width: 16px;
  opacity: 0;
  -webkit-transform: translate(0);
  transform: translate(0);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.view-more-btn:hover {
  color: #ebb632;
  text-decoration: none;
}
.view-more-btn:hover svg {
  opacity: 1;
  -webkit-transform: translate(3px);
  transform: translate(3px);
}
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.pagination-container > span {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.btn-pagi_prev,
.btn-pagi_next {
  background-color: transparent;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: none;
  outline: none;
}
.btn-pagi_prev svg {
  transform: rotate(180deg);
}
.btn-pagi_prev:disabled svg,
.btn-pagi_next:disabled svg {
  opacity: 0.4;
}
span button.btn-pagination {
  width: 32px;
  height: 32px;
  border: none;
  outline: none;
  color: #8a8792;
  background-color: transparent;
  border: 1px solid #be9528;
  font-family: var(--font-primary);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
span button.btn-pagination.active {
  background-color: #be9528;
  color: #000;
}
/* span button.btn-pagination:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
span button.btn-pagination:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
} */
span.pagination-ellipsis {
  width: 32px;
  height: 32px;
  border: none;
  outline: none;
  color: #00000040;
  background-color: transparent;
  display: flex;
  justify-content: center;
  font-family: var(--font-primary);
  font-size: 24px;
  font-weight: 500;
  line-height: 1;
}

.hZVIVT {
  padding: 0 1rem 10px !important;
  display: block !important;
}
.h-54px {
  height: 54px;
}
.navbar_profile {
  position: relative;
}
.navbar_profile_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-weight: 600;
  cursor: pointer;
}
.navbar_profile_btn img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
.navbar_profile_dropdown {
  position: absolute;
  top: 130%;
  right: 0;
  width: 150px;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 2px 5px #00000020;
  padding: 4px 0;
  display: flex;
  flex-direction: column;
}
.navbar_profile_dropdown button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: none;
  background-color: #fff;
  padding: 10px 20px;
  font-size: 15px;
  color: #666;
  font-weight: 500;
}
.navbar_profile_dropdown button:hover {
  background-color: #fafafa;
}
.card.card-img-holder {
  position: relative;
}
.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.3125rem;
}
.bg-gradient-danger {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ffbf96),
    to(#fe7096)
  ) !important;
  background: linear-gradient(to right, #ffbf96, #fe7096) !important;
}
.bg-gradient-info {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#90caf9),
    color-stop(99%, #047edf)
  ) !important;
  background: linear-gradient(to right, #90caf9, #047edf 99%) !important;
}
.bg-gradient-success {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#84d9d2),
    to(#07cdae)
  ) !important;
  background: linear-gradient(to right, #84d9d2, #07cdae) !important;
}
.card-blue {
  background: #b5d3f5 !important;
  color: #075fa5;
}
.ecommerce-banner-image-position {
  position: absolute;
  right: 40px;
  bottom: 0;
  width: 230px;
}
.card.card-img-holder .card-img-absolute {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}
.profile_image {
  position: relative;
  border-radius: 12px;
  background: #fff;
  width: 70px;
  height: 70px;
  display: flex;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid #ccc;
}
.profile_image img {
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.profile_image input {
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  opacity: 0;
}
.profile_image + p {
  position: relative;
  font-size: 14px;
  opacity: 0.7;
}
.profile_info {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}
.profile_info > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
  width: 100%;
}
.profile_info > div > span:first-child {
  color: #777;
}
.profile_info > div > span:last-child {
  font-weight: 500;
}
.profile_info .change_password_link {
  display: inline;
  margin: auto;
  cursor: pointer;
  font-size: 13px;
  text-decoration: underline;
  color: #0056b3;
  margin: 3rem auto 1.5rem;
}
.profile_info .change_password_link:hover {
  color: #0f408d;
}
/* .employer_details_card > div {
  display: flex;
  justify-content: start;
  align-items: center;
} */
.employer_details_card > div {
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 2px 0;
}
.employer_details_card > div > span:first-child {
  color: #555;
  display: flex;
  max-width: 250px;
  width: 100%;
}
.employer_details_card > div > span:last-child {
  font-weight: 500;
}
.profile_name_card {
  background: linear-gradient(267.73deg, #13a9d4 -45.79%, #062048 100%);
  border-radius: 10px;
  display: flex;
  padding: 1rem;
  align-items: center;
  margin-bottom: 1rem;
}
.profile_name_card > img {
  min-width: 80px;
  min-height: 80px;
  max-width: 80px;
  max-height: 80px;
  border-radius: 50%;
}
.profile_name_card > div {
  color: #fff;
  margin-left: 1rem;
  word-break: break-all;
}
.userdetail_tabs .nav-link {
  display: flex;
  align-items: center;
  color: #999;
  font-weight: 600;
  padding: 1rem;
}
.userdetail_tabs .nav-pills .nav-link.active,
.userdetail_tabs .nav-pills .show > .nav-link {
  background-color: #fff;
  color: #0f408d;
  font-weight: 700;
}
.goals_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  border: 1px solid #a8a8a8;
  border-radius: 10px;
  height: 100%;
}
.goals_card p {
  margin: 0;
  text-align: center;
}
.progress_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border: 1px solid #062048;
  border-radius: 10px;
  color: #fff;
  background-color: #062048;
  height: 100%;
}
.progress_card p {
  margin: 12px auto 0;
  text-align: center;
}
.progress_card span {
  color: #667b9a;
}
div.text-primary {
  color: #be9528 !important;
}
.pass_error_msg {
  color: crimson;
  font-size: 13px;
}
.dashboard-card {
  border-radius: 16px;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  min-height: 120px;
}
.dashboard-card.policies {
  background-color: #eaf1fe;
}
.dashboard-card .dashboard-card-name .dash-name {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #000;
}
.dashboard-card .dashboard-card-name .dash-number {
  font-size: 24px;
  font-weight: 500;
}
.dashboard-card.total-renewal {
  background-color: #fef8ed;
}
.dashboard-card.premium {
  background-color: #edf6ed;
}
.dashboard-card.payout {
  background-color: #ce232710;
}

.radial-chart-non-certify-info *,
.radial-chart-certify-info * {
  font-family: "Cera Pro", sans-serif;
  font-weight: 700;
}
.radial-chart-non-certify-info h6,
.radial-chart-certify-info h6 {
  font-size: 14px;
}
.radial-chart-non-certify-info h4 {
  font-size: 24px;
  color: #f5b544;
}
.radial-chart-certify-info h4 {
  font-size: 24px;
  color: #2772f0;
}
.radial-chart-non-certify-info p,
.radial-chart-certify-info p {
  font-size: 14px;
  color: #091b3d50;
}
.radial-chart-non-certify-info .profit-signal,
.radial-chart-certify-info .profit-signal {
  font-size: 14px;
  color: #4b9f47;
}
.radial-chart-non-certify-info .loss-signal,
.radial-chart-certify-info .loss-signal {
  font-size: 14px;
  color: #e6521f;
}
.radial_chart svg {
  width: unset !important;
  height: unset !important;
}
.total-premium-heading {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 1rem;
}
.total-premium-amount {
  font-family: "Cera Pro", sans-serif;
  font-weight: 700;
  color: #091b3d;
}
.total-premium-amount span {
  font-size: 24px;
}
.total-premium-amount p {
  font-size: 14px;
  margin-top: 10px;
}
.total-premium-percent span {
  font-weight: 700;
  font-size: 14px;
  color: #4b9f47;
}
.CircularProgressbar {
  width: 100%;
}
.average-product-sale-progess {
  font-family: "Cera Pro", sans-serif;
}
.average-product-sale-progess > div {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.product-sale-progress {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  max-width: calc(125px - 24px);
}
.product-sale-progress h5 {
  font-weight: 700;
  font-size: 24px;
  color: #091b3d;
  text-align: center;
  margin: 24px 0 10px;
}
.product-sale-progress p {
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  color: #091b3d50;
  margin-bottom: 1rem;
}
.average-product-sale-progess .sale-desc {
  padding: 24px;
  border-radius: 24px;
  background: #f5b54410;
  font-weight: 700;
  font-size: 14px;
  color: #091b3d;
}
/* input[type="checkbox"] {
  width: 16px;
  height: 16px;
  border-radius: 4px;
} */
input[type="checkbox"]:not(:checked) {
  opacity: 0.3 !important;
}
input[type="checkbox"]:checked {
  opacity: 1 !important;
  accent-color: #0909ff;
}
.toggle {
  position: relative;
  display: block;
  width: 34px;
  height: 20px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.toggle:before {
  content: "";
  position: relative;
  top: 3px;
  left: 3px;
  width: 34px;
  height: 14px;
  font-size: 10px;
  display: block;
  background: #e6e6e6;
  border-radius: 8px;
  -webkit-transition: background 0.2s ease;
  -o-transition: background 0.2s ease;
  transition: background 0.2s ease;
}

.toggle span {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  display: block;
  background: white;
  border-radius: 10px;
  -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.toggle span:before {
  content: "";
  position: absolute;
  display: block;
  margin: -18px;
  width: 56px;
  height: 56px;
  background: rgba(105, 80, 186, 0.5);
  border-radius: 50%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  opacity: 1;
  pointer-events: none;
}

.toggle_switch:checked + .toggle:before {
  background: #fef0cb;
}

.toggle_switch:checked + .toggle span {
  background: #be9528;
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
  -webkit-transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25),
    background 0.15s ease;
  -o-transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25),
    background 0.15s ease;
  transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease;
  -webkit-box-shadow: 0 3px 8px rgba(105, 80, 186, 0.2);
  box-shadow: 0 3px 8px rgba(105, 80, 186, 0.2);
}

.toggle_switch:checked + .toggle span:before {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.product_detail_carousel .slider-wrapper {
  position: relative;
}
.product_detail_carousel ul.slider {
  transform: none !important;
  list-style: none;
  padding-left: 0;
}
.product_detail_carousel ul.slider li.slide {
  display: none;
}
.product_detail_carousel ul.slider li.slide.selected {
  display: flex;
}
.product_detail_carousel ul.slider li.slide.previous:last-child {
  display: none;
}
.product_detail_carousel ul.slider li.slide.selected:first-child {
  display: none;
}
.product_detail_carousel ul.slider li.slide.selected:last-child {
  display: none;
}
.product_detail_carousel .slide > div {
  aspect-ratio: 10/9;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
}
.product_detail_carousel .slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.product_detail_carousel .carousel .thumbs-wrapper {
  margin: 24px 0 0;
}
.product_detail_carousel .carousel .thumbs {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  transform: none !important;
}
.product_detail_carousel .carousel .thumb {
  position: unset;
  pointer-events: all;
  height: unset;
  border: none !important;
  margin: 0;
  padding: 0;
  width: 100% !important;
  aspect-ratio: 1;
  border-radius: 10px;
  overflow: hidden;
}
.product_detail_carousel .carousel .thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.product_detail_carousel .control-arrow {
  display: none !important;
}

.product_detail_info > p {
  color: #201838;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
}
.product_detail_quantity {
  color: #635d74;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.032px;
}
.product_detail_star_rating {
  gap: 10px;
  color: #635d74;
  font-weight: 600;
}
.select_dropdown button {
  color: #8a8792 !important;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  border-radius: 12px;
  border: 1px solid #e4e3e7 !important;
  background: #fff !important;
  padding: 18px 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 55px;
}

.dropdown_w_icon button {
  padding: 15px 16px;
}

.select_dropdown button.btn-check:checked + .btn,
.select_dropdown button.btn.active,
.select_dropdown button.btn.show,
.select_dropdown button.btn:first-child:active,
.select_dropdown button:not(.btn-check) + .btn:active {
  color: #8a8792;
  border: 1px solid #e4e3e7;
  background: #fff;
}

.select_dropdown .dropdown-menu {
  width: 100%;
  border-radius: 12px;
  border: 1px solid #e4e3e7;
  z-index: 5;
}

.select_dropdown .dropdown-menu .dropdown-item {
  color: #201838;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.056px;
}

.select_dropdown .dropdown-menu .dropdown-item.active,
.select_dropdown .dropdown-menu .dropdown-item:active {
  background: #fff;
  color: #8a8792;
}
.product_variant_select {
  position: relative !important;
  max-width: 185px;
  margin: 16px 0 20px;
}
.product_variant_select::before {
  position: absolute !important;
  content: "Pack Size :";
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  color: #635d74;
  font-family: var(--font-primary);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.028px;
}
.product_variant_select button {
  padding: 8px 16px 8px 94px;
  color: #be9528;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.028px;
}
.product_variant_select button span {
  text-transform: uppercase;
  color: #be9528;
}
.quantity_action_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 130px;
  padding: 12px 16px;
  border-radius: 12px;
  border: 1px solid #e4e3e7;
  margin-right: 20px;
}
.quantity_action_btn span {
  color: #be9528;
  font-weight: 700;
  letter-spacing: -0.032px;
}
.quantity_action_btn button {
  border: none;
  background: none;
}
.quantity_action_btn button:disabled svg path {
  stroke: #8a8792;
}
.product_delivery_terms {
  border-radius: 12px;
  border: 1px solid #e4e3e7;
  padding: 18px 22px;
}
.product_delivery_terms p {
  color: #201838;
  font-size: 14px;
  margin: 0;
}
.product_delivery_terms p span {
  color: #201838;
  font-size: 14px;
  font-weight: 600;
}
.product_delivery_terms > div {
  margin: 20px 0 16px;
  padding-bottom: 13px;
  border-bottom: 1px solid #e4e3e7;
}

.product_offer_info {
  background-color: #f0edf8;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 18px 22px;
}
.product_offer_info > div {
  gap: 14px;
  height: 20px;
}
.product_offer_info > div span {
  width: 32px;
  height: 100%;
  display: flex;
  align-items: center;
}
.product_offer_info > div span img {
  height: 100%;
}
.product_offer_info > div h6 {
  color: #201838;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}
.product_offer_info > div p {
  color: #635d74;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.028px;
  margin: 0;
}
.product_detail_desc {
  border: 1px solid #e4e3e7;
  border-radius: 12px;
  overflow: hidden;
}
.product_detail_desc h6 {
  background-color: #f3f5f8;
  color: #201838;
  font-size: 18px;
  font-weight: 700;
  padding: 20px 22px 15px;
  margin: 0;
}
.product_detail_desc div {
  padding: 20px 22px;
}
.product_detail_desc span {
  color: #635d74;
  font-weight: 500;
  letter-spacing: -0.032px;
  display: block;
}
.product_detail_desc p {
  color: #8a8792;
  font-size: 14px;
  line-height: 151.9%;
  letter-spacing: -0.028px;
  margin: 0;
}
.product_detail_review_box {
  border: 1px solid #e4e3e7;
  border-radius: 12px;
  overflow: hidden;
}
.product_detail_review_heading {
  background-color: #f3f5f8;
  padding: 20px 22px;
}
.product_detail_review_heading h6 {
  color: #201838;
  font-size: 18px;
  font-weight: 700;
}
.product_detail_review_heading > div img {
  width: 40px;
}
.product_detail_review_heading > div p {
  color: #635d74;
  font-size: 24px;
  font-weight: 400;
  margin: 0;
}
.product_detail_review_heading > div p span {
  color: #201838;
  font-size: 28px;
  font-weight: 600;
}
.product_detail_review_box ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.product_detail_review_box li {
}
.product_detail_review_box li + li {
  border-top: 5px solid #f3f5f8;
}
.product_detail_review_box li p {
  color: #8a8792;
  font-size: 14px;
  line-height: 140%; /* 20.636px */
  letter-spacing: -0.1px;
  margin-bottom: 0;
}
.product_detail_review_box .product_detail_review_profile {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
}
.product_detail_review_box .product_detail_review_profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.product_detail_review_name p {
  color: #201838;
  font-weight: 600;
}
.product_detail_review_name .review_star_icon {
  width: 20px;
  height: 16px;
}
.product_detail_review_box .product_detail_review {
  padding: 20px 16px;
}
.product_detail_review_box .product_detail_review_viewall {
  background-color: #f3f5f8;
  padding: 20px 25px;
}
/* doctor detail page start */
.doctor_details_page,
.payment_page {
  padding: 50px 0 80px;
}

.about_veterian_box h6,
.payment_method_box h6,
.payment_details_box h6 {
  color: #201838;
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 3px dashed #c3b9e350;
}

.about_veterian_box span,
.payment_method_box > span {
  color: #201838;
  font-size: 16px;
  font-weight: 600;
  display: block;
  margin-bottom: 15px;
}

.about_veterian_box ul {
  padding-left: 1rem;
  margin-bottom: 40px;
}

.about_veterian_box ul li {
  color: #635d74;
  font-size: 14px;
  margin-bottom: 10px;
}

.about_veterian_box p {
  color: #635d74;
  font-size: 14px;
}

.revews_box h6 {
  color: #201838;
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 3px dashed #c3b9e350;
}

.revews_box ul {
  list-style: none;
  padding: 0;
}

.revews_box ul li + li {
  border-top: 1px solid #e4e3e7;
  padding-top: 20px;
  margin-top: 15px;
}

.revews_box .visited_for {
  color: #201838;
  font-weight: 600;
  display: block;
}

.revews_box .visited_at {
  color: #635d74;
  font-size: 14px;
  display: block;
  margin: 8px 0;
}

.revews_box p {
  color: #635d74;
  font-size: 14px;
}

.revews_box li > div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.revews_box img {
  min-width: 36px;
  max-width: 36px;
  min-height: 36px;
  max-height: 36px;
  object-fit: cover;
  border-radius: 50%;
}

.revews_box .review_from {
  color: #201838;
  font-weight: 600;
  display: block;
}

.revews_box .breed_owner {
  color: #635d74;
  font-size: 12px;
  display: block;
}

.revews_box .view_all_btn {
  color: #be9528;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  padding: 0;
  background-color: transparent;
  border: none;
}

.revews_box .view_all_btn:hover {
  color: #ecb732;
}
.product_detail_review_viewall .view_all_btn {
  background-color: #be9528;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  border-radius: 6px;
  padding: 9px 12px;
  border: none;
  outline: none;
  transition: all 0.2s ease;
}
.doctor_info_card .doctor_info_card_photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.doctor_info_card .doctor_info_card_name h6,
.appointment_details_card .doctor_info_card_name h6 {
  color: #201838;
  font-size: 26px;
  font-weight: 700;
}

.doctor_info_card .doctor_info_card_name div,
.appointment_details_card .doctor_info_card_name div {
  display: flex;
  align-items: center;
  gap: 4px;
}

.appointment_details_card .doctor_info_card_name p {
  color: #201838;
  font-weight: 600;
  margin: 0;
}

.doctor_info_card .doctor_info_card_name span {
  color: #201838;
  font-size: 12px;
  font-weight: 600;
}

.doctor_info_card .doctor_info_card_profession {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 0;
  margin: 18px 0;
  border-top: 3px dashed #c3b9e350;
  border-bottom: 3px dashed #c3b9e350;
}

.doctor_info_card .doctor_info_card_profession span {
  color: #635d74;
  font-size: 12px;
}

.doctor_info_card .doctor_info_card_profession p {
  color: #201838;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}

.doctor_info_card .doctor_info_card_pet_category > div > p {
  color: #635d74;
  font-size: 14px;
}

.doctor_info_card .doctor_info_card_pet_category > div > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.doctor_info_card .doctor_info_card_pet_category > div > div > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px solid #e4e3e7;
  border-radius: 8px;
}

.doctor_info_card .doctor_info_card_pet_category > div > div img {
  min-height: 34px;
  max-height: 34px;
  min-width: 34px;
  max-width: 34px;
  margin: 12px 0;
}

.doctor_info_card .doctor_info_card_pet_category > div > div p {
  color: #201838;
  font-size: 12px;
  font-weight: 500;
}
.btn_readmore {
  color: #be9528;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  padding: 0;
  background-color: transparent;
  border: none;
}
.input_dark > label {
  color: #1c1c21;
  font-size: 14px;
  font-weight: 500;
}
.input_dark > input,
.input_dark > select {
  background-color: #eeeeee;
  height: 46px;
  color: #1c1c21;
  font-size: 14px;
  font-weight: 600;
}
.input_dark > select:focus {
  background-color: #eeeeee;
  height: 46px;
  color: #1c1c21;
  font-size: 14px;
  font-weight: 600;
}
.input_dark > input:focus {
  background-color: #eeeeee;
}
.input_dark > textarea {
  background-color: #eeeeee;
  height: auto;
  color: #1c1c21;
  font-size: 14px;
  font-weight: 600;
}
.input_dark > input::placeholder,
.input_dark > textarea::placeholder {
  color: #1c1c21;
}
.input_dark > textarea:focus {
  background-color: #eeeeee;
}
.input_dark_email > label {
  color: #1c1c21;
  font-size: 14px;
  font-weight: 500;
}
.input_dark_email > div {
  display: flex;
  align-items: center;
  background-color: #eeeeee;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}
.input_dark_email > div > input {
  height: 46px;
  color: #1c1c21;
  font-size: 14px;
  font-weight: 600;
  border-radius: 0;
  border-radius: 8px 0 0 8px;
}
.input_dark_email > div > span {
  background-color: #eeeeee;
  height: 46px;
  color: #1c1c21;
  font-size: 14px;
  font-weight: 600;
  border-radius: 0;
}
.input_dark > div > input:focus {
  background-color: #eeeeee;
}
.input_dark_email > input::placeholder {
  color: #1c1c21;
}
.list_img {
  width: 35px;
  height: 35px;
  margin: 4px 0;
  border-radius: 50%;
}
.certificate_images {
  display: flex;
  gap: 8px;
}
.certificate_images img {
  height: 140px;
  width: 100px;
}
.dashboard-card {
  border-radius: 16px;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  min-height: 120px;
}
.dashboard-card.policies {
  background-color: #eaf1fe;
}
.dashboard-card .dashboard-card-name .dash-name {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #000;
}
.dashboard-card .dashboard-card-name .dash-number {
  font-size: 24px;
  font-weight: 500;
}
.dashboard-card.total-renewal {
  background-color: #fef8ed;
}
.dashboard-card.premium {
  background-color: #edf6ed;
}
.dashboard-card.payout {
  background-color: #ce232710;
}

.radial-chart-non-certify-info *,
.radial-chart-certify-info * {
  font-family: "Cera Pro", sans-serif;
  font-weight: 700;
}
.radial-chart-non-certify-info h6,
.radial-chart-certify-info h6 {
  font-size: 14px;
}
.radial-chart-non-certify-info h4 {
  font-size: 24px;
  color: #f5b544;
}
.radial-chart-certify-info h4 {
  font-size: 24px;
  color: #2772f0;
}
.radial-chart-non-certify-info p,
.radial-chart-certify-info p {
  font-size: 14px;
  color: #091b3d50;
}
.radial-chart-non-certify-info .profit-signal,
.radial-chart-certify-info .profit-signal {
  font-size: 14px;
  color: #4b9f47;
}
.radial-chart-non-certify-info .loss-signal,
.radial-chart-certify-info .loss-signal {
  font-size: 14px;
  color: #e6521f;
}
.radial_chart svg {
  width: unset !important;
  height: unset !important;
}
.total-premium-heading {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 1rem;
}
.total-premium-amount {
  font-family: "Cera Pro", sans-serif;
  font-weight: 700;
  color: #091b3d;
}
.total-premium-amount span {
  font-size: 24px;
}
.total-premium-amount p {
  font-size: 14px;
  margin-top: 10px;
}
.total-premium-percent span {
  font-weight: 700;
  font-size: 14px;
  color: #4b9f47;
}
.CircularProgressbar {
  width: 100%;
}
.average-product-sale-progess {
  font-family: "Cera Pro", sans-serif;
}
.average-product-sale-progess > div {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.product-sale-progress {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  /* // max-width: calc(125px - 24px); */
}
.product-sale-progress h5 {
  font-weight: 700;
  font-size: 24px;
  color: #091b3d;
  text-align: center;
  margin: 24px 0 10px;
}
.product-sale-progress p {
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  color: #091b3d50;
  margin-bottom: 1rem;
}
.average-product-sale-progess .sale-desc {
  padding: 24px;
  border-radius: 24px;
  background: #f5b54410;
  font-weight: 700;
  font-size: 14px;
  color: #091b3d;
}
.sidebar .nav .nav-item + .nav .nav-item .nav-link i {
  font-size: 1.125rem;
  line-height: 1;
  margin-left: auto;
  color: rgba(153, 174, 161, 0.9607843137);
}
.setting_checkbox {
  opacity: 100% !important;
  position: unset !important;
  margin: 2px 8px 0 0;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fafafa !important;
}
.form-control,
.swal2-modal .swal2-content .swal2-input {
  border: 1px solid #e0e0e0 !important;
}
select.form-control,
.swal2-modal .swal2-content select.swal2-input {
  outline: 0 !important;
  color: #495057 !important;
}
select.form-control::placeholder,
.swal2-modal .swal2-content select.swal2-input::placeholder {
  outline: 0 !important;
}
/* .btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url(../assets/images/btn_close.svg);
  background-position: center;
  background-size: 1em;
  background-repeat: no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
} */
.pastel_red {
  background-color: #990d0e !important;
}
.pastel_violet {
  background-color: #484848 !important;
}
.pastel_blue {
  background-color: #dbae58 !important;
}
.pastel_yellow {
  background-color: #dadada !important;
  color: #000 !important;
}
.piechart_list {
  list-style: none;
  padding: 0;
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
  justify-content: center;
}
.piechart_list li {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  padding: 10px;
}
.piechart_list li > div {
  width: 18px;
  height: 10px;
}
.piechart_list li > p {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}
label.selectCheck {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  gap: 10px;
}
label.selectCheck span {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #696974;
  user-select: none;
}
label.selectCheck input {
  display: none;
  margin-bottom: 0;
}
label.selectCheck input + span {
  height: 20px;
  width: 20px;
  border-radius: 50px;
  border: 1px solid #7a7a8d8c;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -2px;
}
label.selectCheck input + span img {
  opacity: 0;
  transition: 0.3s;
}
label.selectCheck input:checked + span img {
  opacity: 1;
}
.product-img-upload {
  padding: 4px;
  border: 2px dashed #ddd;
  border-radius: 12px;
  position: relative;
  width: 100%;
  aspect-ratio: 4/3;
  & > div {
    position: relative;
    min-height: 200px;
    height: 100%;
    img {
      inset: 0;
      object-fit: scale-down;
    }
  }
}

.upload-new-product-img {
  position: absolute;
  opacity: 0;
  height: 100%;
  z-index: 2;
  inset: 0;
  input {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
.filterby_label {
  font-size: 14px;
}
.table_filter {
  display: flex;
  /* border: 1px solid #e0e0e0;
  border-radius: 2px;
  padding: 4px; */
  gap: 12px;
}
.table_filter input.form-control,
.table_filter select.form-control {
  height: 45px;
  padding: 8px 16px;
  font-size: 14px;
}
.jodit-container:not(.jodit_inline) .jodit-wysiwyg {
  min-height: 165px !important;
}
.btn_link {
  color: var(--primary);
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  background: none;
  border: none;
  margin: 8px 0;
}
.btn_link:hover {
  color: #a88423;
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  background: none;
  border: none;
  margin: 8px 0;
}
.warn_text {
  color: #999;
  font-weight: 500;
}
.time-slot-box > div + div {
  border-top: 1px solid #ddd;
}
.time-slot-box > div ul {
  list-style: none;
}
.time-slot-box > div ul li {
  padding: 4px 0;
  margin: 4px 0;
}
button.circle-cross-btn {
  width: 25px;
  height: 25px;
  border: 0;
  margin: 0 0 0 16px;
  border-radius: 50%;
}
.add-more-btn {
  border: none;
  background: transparent;
  color: var(--primary);
}

.slot_timing_show + .slot_timing_show {
  border-top: 1px solid #ddd;
}
.slot_timing_show h6 {
  min-width: 100px;
}
.slot_timing_show > div {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.slot_timing_show > div > span {
  width: 150px;
  border: 1px solid #ddd;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
}
.react-time-picker__clock-button.react-time-picker__button {
  display: none !important;
}
.react-time-picker__clock.react-time-picker__clock--open {
  display: none !important;
}
.react-time-picker__wrapper {
  border: none !important;
}
.location_list {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 2;
  background-color: #fff;
  padding: 8px 6px;
  box-shadow: 0px 5px 10px #00000020;
  left: 0;
  right: 0;
}
.location_list ul {
  max-height: 200px;
  overflow-y: scroll;
  list-style: none;
  padding: 0;
  margin-bottom: 8px;
}
.location_list input {
  border-radius: 10px;
  margin-bottom: 16px;
  padding: 8px 16px;
  height: 40px;
}
.location_list ul li {
  display: flex;
  gap: 8px;
  padding: 4px;
  cursor: pointer;
  img {
    width: 22px;
  }
}
.location_list ul li + li {
  margin-top: 6px;
}
.cJTPDY div:first-child,
.erkbiK div:first-child {
  white-space: normal !important;
  overflow: unset !important;
}